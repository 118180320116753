import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYou = () => (
  <Layout>
    <SEO title="Thank You Page" />
    <div
      className="thank-you-wrapper valueprop section"
      style={{ paddingTop: "20rem", paddingBottom: "10rem" }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <h1 className="font-weight-bold text-center mb-3 h-ls">
              <strong>Thank you for trusting us</strong>
            </h1>
            <h5 className="text-center">
              {/* Gray Hawk offers process &amp; technology that are one of a kind. */}
            </h5>
            <div className="text-center mt-5 ">
              {/* <div className="mb-3">
                <Link to="/" type="button" className="request-btn mr-3">
                  BACK To HOME
                </Link>
              </div> */}
              <Link to="/" className="btn-global hvr-icon-forward">
                GO BACK HOME
                <i className="fa fa-chevron-right ml-2 hvr-icon"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ThankYou
